.icon-amazon {
  @include icon(amazon);
}

.icon-apple {
  @include icon(apple);
}

.icon-cart {
  @include icon(cart);
}

.icon-check {
  @include icon(check);
}

.icon-check--outline {
  @include icon-outline(check);
}

.icon-comments {
  @include icon(comments);
}


.icon-discord {
  @include icon(discord);
}

.icon-ebay {
  @include icon(ebay);
}

.icon-envelope {
  @include icon(envelope);
}

.icon-expand {
  @include icon(expand);
}

.icon-facebook {
  @include icon(facebook);
}

.icon-google-play {
  @include icon(google-play);
}

.icon-instagram {
  @include icon(instagram);
}

.icon-kickstarter {
  @include icon(kickstarter);
}

.icon-link {
  @include icon(link);
}

.icon-linkedin {
  @include icon(linkedin);
}

.icon-mastodon {
  @include icon(mastodon);
}

.icon-patreon {
  @include icon(patreon);
}

.icon-paypal {
  @include icon(paypal);
}

.icon-pinterest {
  @include icon(pinterest);
}

.icon-reddit {
  @include icon(reddit);
}

.icon-rss {
  @include icon(rss);
}

.icon-snapchat {
  @include icon(snapchat);
}

.icon-telegram {
  @include icon(telegram);
}

.icon-tiktok {
  @include icon(tiktok);
}

.icon-twitch {
  @include icon(twitch);
}

.icon-twitter {
  @include icon(twitter);
}

.icon-whatsapp {
  @include icon(whatsapp);
}

.icon-x {
  @include icon(x);
}

.icon-x--outline {
  @include icon-outline(x);
  background-size: 50%;
}

.icon-list {
  @include icon(list)
}

.icon-youtube {
  @include icon(youtube);
}

.icon-button {
  filter: var(--icon-button-filter);

  [data-color-scheme=dark] & {
    filter: var(--icon-button-filter);
  }

  .cta & {
    transform: translate(-10px, -1.5px);
  }

  .cta:hover & {
    filter: var(--icon-button-filter--hover);
  }
}