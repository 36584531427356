figure.image {
  @at-root &.image--cover,
  %image--cover{
    img {
      object-fit: cover;
    }
  }
  
  @at-root &.image--contain,
  %image--contain {
    img {
      object-fit: contain;
    }
  }

  @at-root &.image--rounded,
  %image--rounded {
    img {
      border-radius: 290486px
    }
  }

  // Ratio
  @at-root &.image--square,
  %image--square,
  &.image--1x1,
  %image--1x1,
  &.image--5x4,
  %image--5x4,
  &.image--4x3,
  %image--4x3,
  &.image--3x2,
  %image--3x2,
  &.image--5x3,
  %image--5x3,
  &.image--16x9,
  %image--16x9,
  &.image--2x1,
  %image--2x1,
  &.image--3x1,
  %image--3x1,
  &.image--4x5,
  %image--4x5,
  &.image--3x4,
  %image--3x4,
  &.image--2x3,
  %image--2x3,
  &.image--3x5,
  %image--3x5,
  &.image--9x16,
  %image--9x16,
  &.image--1x2,
  %image--1x2,
  &.image--1x3,
  %image--1x3 {

    img {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      top: 0;
      height: 100%;
      width: 100%;
    }
  }

  @at-root &.image--square,
  %image--square,
  &.image--1x1,
  %image--1x1 {
    padding-top: 100%;
  }

  @at-root &.image--5x4,
  %image--5x4 {
    padding-top: 80%;
  }

  @at-root &.image--4x3,
  %image--4x3 {
    padding-top: 75%;
  }

  @at-root &.image--3x2,
  %image--3x2 {
    padding-top: 66.6666%;
  }

  @at-root &.image--5x3,
  %image--5x3 {
    padding-top: 60%;
  }

  @at-root &.image--16x9,
  %image--16x9 {
    padding-top: 56.25%;
  }

  @at-root &.image--2x1,
  %image--2x1 {
    padding-top: 50%;
  }

  @at-root &.image--3x1,
  %image--3x1 {
    padding-top: 33.3333%;
  }

  @at-root &.image--4x5,
  %image--4x5 {
    padding-top: 125%;
  }

  @at-root &.image--3x4,
  %image--3x4 {
    padding-top: 133.3333%;
  }

  @at-root &.image--2x3,
  %image--2x3 {
    padding-top: 150%;
  }

  @at-root &.image--3x5,
  %image--3x5 {
    padding-top: 166.6666%;
  }

  @at-root &.image--9x16,
  %image--9x16 {
    padding-top: 177.7777%;
  }

  @at-root &.image--1x2,
  %image--1x2 {
    padding-top: 200%;
  }

  @at-root &.image--1x3,
  %image--1x3 {
    padding-top: 300%;
  }
}