#admin-menu {
  position: static !important;;
  box-shadow: inherit;
}

#admin-menu, #admin-menu .dropdown{
  z-index: 9999;
}

#admin-menu .dropdown .admin-menu-search-results {
    display: block !important;
    left: auto !important;
    top: auto;
    margin-top:25px;
}

html body.admin-menu-with-shortcuts {
    margin-top: 0px !important;
}
