.taxonomy{
  max-width: var(--section-wide-desktop-max-width);
  margin-left: auto;
  margin-right: auto;

  .article-bio__avatar {
    border-radius: 50%;
    float: left;
    margin: 24px 20px 0 0;
  }
  .article-bio__name {
    margin-bottom: 0px;
  }
  .article-bio p {
    display: table;

    a {
      @include link-underline;
    }
  }
}

.taxonomy__header{
  @extend .block__header;
  text-align: $taxonomy-header-text-align;
}

.taxonomy__pager{
  ul.pager{
    li{
      padding: 0;
      margin: 0;
    }
    
    li.pager-current,
    a{
      @include font-family($font-paragraph-light);
      color: $taxonomy-pager-list-item-color;
      background-color: $taxonomy-pager-list-item-background-color; 
      text-transform: uppercase;
      font-weight: normal;
      padding: 4px 10px;
      margin: 0 4px;
      font-size: 14px;
      
      &:hover{
        text-decoration: none;
        background-color: darken($taxonomy-pager-list-item-background-color, 5%);
      }
    }
    
    li.pager-current{
      color: $taxonomy-pager-list-item-color--current;
      background-color: $taxonomy-pager-list-item-background-color--current;
    }
  }
}