ul.primary{
  border-color: $drupal-tabs-list-border-color;
  overflow: hidden;
  padding-top: 1px;

  li a{
    background-color: $drupal-tabs-list-item-background-color;
    border-color: $drupal-tabs-list-border-color;
    color: var(--body-text-color);
    font-weight: normal;
    
    &:hover,
    &:focus{
      background-color: $drupal-tabs-list-item-background-color--hover;
      border-color: $drupal-tabs-list-border-color;
    }
  }
  
  li.active a{
    background-color: $drupal-tabs-list-item-background-color--active;
    border-color: $drupal-tabs-list-border-color;
    border-bottom-color: var(--body-background-color);
    color: var(--body-text-color);
    
    &:hover,
    &:focus{
      background-color: $drupal-tabs-list-item-background-color--active;
    }
  }
  
}

