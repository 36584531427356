.sidebar {
  order: 1;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;


  @include desktop {
    order: 0;
  }
}

.sidebar--fixed{
  @include tablet{
    width: var(--sidebar-width);
    flex: 0 0 var(--sidebar-width);
  }
}

.sidebar__section {
  text-align: center;
  flex-grow: 1;
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: flex-start;
  
  &:nth-child(n+2):last-of-type {
    flex-grow: 0;
  }

  &.sidebar__section--shrink{
    flex-grow: 0;
  }

  .article &,
  .topic &{
    margin-bottom: 15px;
  }
  
  .article &:last-of-type {
    margin-bottom: 0;
  }

  .article &:first-of-type .block {
    margin-top:0;
  }


  .block--last &{
    margin-bottom: 30px;
    
    @include desktop{
      margin-bottom: 15px;
    }
  }
  
  > div{
    @include sticky-header-offset(25px)
  }
}
