
// Common - Layout
//------------------------------------------------------------------------------

@mixin col {
  margin: 0 $container-padding;
  padding: 0;
}

/* Container */
.container {
  margin: 0 auto;
  max-width: $container-width + $container-padding*2;
  padding: 0 $container-padding;
  position: relative;
  width: 100%;
}

.container--nopadding {
  max-width: $container-width;
  padding: 0;
}

.row,
.panels-flexible-region-inside {
  margin: 0 (0 - $container-padding);
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  
}

.row--no-wrap{
  @include tablet{
    flex-wrap: nowrap;
  }
}  

// .row--space-around {
//   justify-content: space-between;
// }

.col-1of2 {
  @include col;

  width: calc(50% - #{$container-padding*2} - 0.01px);
}

.col-2of2 {
  @include col;

  width: 100%;
}

.col-1of3 {
  @include col;

  width: calc(33.33% - #{$container-padding*2});
}

.col-2of3 {
  @include col;

  width: calc(66.66% - #{$container-padding*2});
}

.col-3of3 {
  padding: 0 $container-padding;
  width: 100%;
}

// .col-4of4{
//   @include col;
// 
//   width: 100%;
// }
// 

@include mobile {

  .col-1of2\@mobile {
    @include col;

    width: calc(50% - #{$container-padding*2} - 0.01px);
  }
}

@include tablet {
  .col-1of2\@tablet {
    @include col;

     width: calc(50% - #{$container-padding*2} - 0.01px);
  }

  .col-2of3\@tablet {
    @include col;

    width: calc(66.666% - #{$container-padding*2});
  }

  .col-1of3\@tablet {
    @include col;

    width: calc(33.333% - #{$container-padding*2});
  }
  
//   .col-1of6\@tablet {
//     @include col;
//     
//     width: calc(16.6666666667% - #{$container-padding*2});
//   }
}

@include tablet-to-desktop {
  .col-hidden\@tablet {
    display: none;
  }
}

@include desktop {
  .col-1of3\@desktop {
    @include col;

    width: calc(33.33% - #{$container-padding*2} - 0.01px);
  }

  .col-2of3\@desktop {
    @include col;

    width: calc(66.66% - #{$container-padding*2} - 0.01px);
  }

  .col-2of3\@desktop {
    @include col;

    width: calc(66.66% - #{$container-padding*2} - 0.01px);
  }

  .col-1of2\@desktop {
    @include col;

    width: calc(50% - #{$container-padding*2} - 0.01px);
  }
  
//   .col-1of8\@desktop {
//     @include col;
//     
//     width: calc(12.5% - #{$container-padding*2} - 0.01px);
//   }
// 
//   .col-1of4\@desktop {
//     @include col;
//     width: calc(25% - #{$container-padding*2});
//   }
}

.content {
  flex-grow: 1;

  &:focus {
    outline: none;
  }
}

.panels-flexible-region .col-1of2\@tablet:nth-child(3):last-child {
  @include tablet-to-desktop {
    width: 100%;
  }
}

