.header-top__navigation-item--search-visible {
  button,
  a {
    background-color: var(--header-top-navigation-item-background-color--search-visible);
    color: var(--header-top-navigation-item-text-color--search-visible);

    &:focus,
    &:hover {
      background-color: var(--header-top-navigation-item-background-color--search-visible);
      color: var(--header-top-navigation-item-text-color--search-visible);
    }
  }
}

.header-top__navigation-search-form {
  position: absolute;
  right: var(--header-top-height);
  top: 0;
  transform: translateY(-101%);
  transition: transform 0.25s ease 0s;
  z-index: 40;
  height: 100%;
  background-color: var(--header-top-navigation-search-form-background-color);

  .header-top--scrolled & {
    right: var(--header-top-height--scrolled);
  }

  @include tablet {
    right: var(--header-top-height--tablet);

    .header-top--scrolled & {
      right: var(--header-top-height--tablet-scrolled);
    }
  }

  body & {
    display: block;
  }

  button {
    display: none;
  }

  input {
    @include font-family($header-top-navigation-search-form-font-family);
    height: 100%;
    background-color: transparent;
    border-width: 0;
    padding: 0 10px;
    color: var(--header-top-navigation-search-form-text-color);

    &:focus {
      outline: none;
    }
  }

  .header-top__navigation-item--search-visible & {
    transform: translateY(0%);
    transition: transform 0.25s ease 0s;
  }
}