.search__header{
  @extend .block__header;
}

.search__form{
  margin-bottom: 30px;
  display: flex;
  border: 1px solid #ccc;
}

.search__input{
  @extend .block-topic__input;
  flex-grow: 1;
  max-width: none;
}

.search__submit{
  @extend .block-topic__submit;
  order: initial;
}

.search__list .adunit-wrap{
  margin-bottom: 20px;
}