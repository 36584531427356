.header-top {
  .cover-page & {
    @include tablet {
      display: none;
      height: 0;
    }
  }
}

.cover-page .header-top.header-top--scrolled {
  display: block;
}


.header-top__panel-title {
  @include font-family($font-heading-bold);
  font-size: 20px;
  line-height: $header-top-panel-title-line-height;
  height: 100%;
  white-space: nowrap;
  display: block;
  flex-grow: 1;
  text-overflow: ellipsis;
  overflow: hidden;
  margin-right: 10px;

  @include tablet {
    flex-shrink: 0;
    line-height: $header-top-panel-title-line-height--tablet;
  }

  .header-top--scrolled & {
    line-height: $header-top-panel-title-line-height--scrolled;
  }
}
