.header-side {
  height: 470px;
  width: 60px;
  background-color: $header-side-background-color;
  position: relative;
  display: none;
  top: 0;
  left: 0;
  z-index: 110;
  float:left;

  @include tablet{
    display: block;
  }

  &.header-side--active {
    visibility: hidden;
  }
}

.header-side__navigation-list {
  @extend .header-drawer__navigation-list;
}

.header-side__navigation-list--nav{
  @extend .header-drawer__navigation-list--nav;
}

.header-side__navigation-item--nav{
  @extend .header-drawer__navigation-item--nav;
}

.header-side__navigation-item--nav-nav{
  @extend .header-drawer__navigation-item--nav-nav;
}

.header-side__navigation-item--nav-forum,
.header-side__navigation-item--nav-dev,
.header-side__navigation-item--nav-shop{
  @extend .header-drawer__navigation-item--nav-shop;
}

.header-side--active.header-side--forum .header-side__navigation-item--nav-forum,
.header-side--active.header-side--nav .header-side__navigation-item--nav-nav,
.header-side--active.header-side--dev .header-side__navigation-item--nav-dev,
.header-side--active.header-side--shop .header-side__navigation-item--nav-shop{
  button i::before{
    content: "\f00d";
  }
}

.header-drawer--forum .header-drawer__tools,
.header-drawer--dev .header-drawer__tools{
  visibility: hidden;
}
