@include font-face('mona-icons', '/sites/all/fonts/mona-icons', normal, normal, woff svg ttf eot );

[class^="mi-"],
[class*=" mi-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'mona-icons' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.mi-comments:before {
  content: "\b2";
}

.mi-shop:before {
  content: "\b3";
}

.mi-comment:before {
  content: "\b1";
}
