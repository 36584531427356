.adunit{
  overflow: hidden;
  text-align: center;
  margin: 20px 0;
  
  &.inline-text{
    margin: 20px -15px;
    clear: both;
  }
}

.adunit-wrap{
  overflow: hidden;
  display: flex;
  justify-content: center;

}

.disable-ads {
  margin-top: 0 !important;

  .adunit-wrap,
  .adunit,
  #bigtop-masthead,
  #ctMediaContainer {
    display: none !important;
  }
}

.ad-container--horizontal {
  display: none;

  @include desktop {
    display: block;
    height: $ad-horizontal-height;
    position: absolute;
    bottom: 0;
    overflow: hidden;
    padding-left: 6px;
  }

  img{
    max-width: 100%;
    height: auto;
  }
}

.ad-container--vertical {
  background-size: 300px 600px;
  background-repeat: no-repeat;
  background-position: center top;
  overflow: hidden;
}

body.mobile .ad-container--vertical{
  margin: 20px 0;
}

.block--big-bottom-ad .ad-container--vertical{
  // min-height: 510px;
}

.ad-container--follow {
  @include tablet {
    position: relative;
  }
}

.adunit-wrap {
  div[class*=header] > & {
    margin: 0;

    div.adunit {
      min-height: 0 !important;

      > *{
        margin: 25px 0;

        &:empty {
          margin: 0;
        }
      }
    }
  } 
}

.region-page-header > .adunit,
.region-page-header > .adunit-wrap {
  align-items: center;
  display: flex;
  justify-content: center;
  //min-height: 300px;

  @include mobile {
    //min-height: 190px;
  }
}

.page-new-homepage .region-site-header>.adunit-wrap{
  order: -80;

  @include tablet{
    order: 0;
  }
}

.ct_wallpaper .region-page-header .adunit-wrap,
.ct_wallpaper .page-header
{
  background: #f4f4f4
}

body .ym-wrapper-footer {
  z-index: 12312321 !important;
}

#topic-pencil-rt.adunit--rendered, body.enable-ads #topic-pencil-rt {
  min-width:100%;
  width: 100vw;

  @include tablet {
    width: 385px;
  }
}

#bigtop-masthead {
  ~ #ctBoc {
    .page-header {
      .adunit {
        display: none;
      }
    }
  }
}