.badge {
  @extend %circle;

  background-color: $badge-default-bg;
  color: $badge-default-color;
  display: inline-block;
  font-size: 13px;
  padding: $badge-padding;
  line-height: 1.2em;
  box-shadow: 1px 1px 1px rgba(#000, .16);
  font-weight: 400;
  white-space: nowrap;
}

.badge i {
  margin-right: 5px;
  font-size: 16px;
}

// .badge--comments {
// }

.bagde--comments-small {
  font-size: 11px;
}
