.header-top-alert-bar {
  font-family: $header-top-alert-bar-font-family;
  color: var(--header-top-alert-bar-text-color);
  background-color: var(--header-top-alert-bar-background-color);
  text-align: center;
  font-size: 14px;
  line-height: 19px;
  min-height: 29px;
  padding: 5px 5px;
  position: relative;
  z-index: 110;
  order: -90;

  a {
    text-decoration: underline;
    margin: 0 5px;
  }

  p{
    margin: 0;
  }
}

.alert-bar-close,
.alert-bar-collapse {
  position: absolute;
  right: 10px;
  line-height: 25px;
  cursor: pointer;

  &:hover {
    font-size: larger;
  }
}

.alert-bar-close {
  display: none;
}

.header-top-alert-bar--close {
  display: none;

  .alert-bar-close {
    display: inline;
  }
}

.header-top-alert-bar__label {
  display: none;

  @include tablet {
    display: inline;
  }
}

.header-top-alert-bar--banner {
  line-height: 0 !important;
  padding: 0 15px;

  a {
    display: block;

    img {
      height: 100%;
      object-fit: contain;
      max-height: 96px;
      width: 100%;

      @at-root .header-top-alert-bar--banner-short#{&} {
        max-height: 96px;
      }

      @at-root .header-top-alert-bar--banner-tall#{&} {
        max-height: 250px;
      }
    }
  }
}