.node-unpublished {
  &:before {
    content: "";
    background-image: url(#{$assets-path}/images/draft.svg);
    background-position: center;
    background-size: contain;
    display: block;
    left: 20px;
    height: 20px;
    margin-left: 50%;
    transform: translate(-50%, 0);
    width: var(--section-wide-max-width);
    z-index: 9000;
    @include sticky-header-offset(0px)
  }

  .node-preview-link &:before {
    background-image: url(#{$assets-path}/images/preview.svg);
    margin-top: -10px;
  }
}