.btn {
  @extend %button-reset;

  border-radius: 4px;
  font-family: "FontAwesome", $font-paragraph-medium;
  text-indent: 0;

  &:hover,
  &:focus {
    text-decoration: none;
  }
  
  // Legacy override
  &.rate-button{
    font-family: $font-font-awesome;
  }
}