.header-drawer {
  background-color: rgba(#000, .8);
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: 0;
  z-index: 130;
  opacity: 0;
  overflow: hidden;
  pointer-events: none;
  transition: opacity .3s ease;
  will-change: opacity;
  -webkit-overflow-scrolling: touch;

  body & {
    display: block;
  }
}

.header-drawer--active {
  -webkit-backdrop-filter: var(--body-backdrop-filter);
  backdrop-filter: var(--body-backdrop-filter);
  cursor: pointer;
  opacity: 1;
  overflow-y: scroll;
  pointer-events: initial;
}

.header-drawer__inner {
  @include font-family($font-paragraph-light);
  background-color: $header-drawer-inner-background-color;
  cursor: default;
  color: $header-drawer-inner-text-color;
  display: flex;
  flex-direction: column;
  font-size: 16px;
  gap: 30px;
  height: auto;
  max-width: 100%;
  overflow: hidden;
  padding: 10px 20px 20px 60px;
  position: relative;
  transform: translateX(-105%);
  transition: visibility 0s .3s, transform .3s ease;
  width: 400px;
  will-change: transform;
  visibility: hidden;

  @include tablet{
    display: grid;
    gap: 40px 60px;
    grid-template-columns: 20px auto;
    padding: 20px 20px 20px 0;
    width: min-content;
  }
}

.header-drawer--active .header-drawer__inner {
  transform: none;
  visibility: visible;
  transition: visibility 0s, transform .3s ease;
}

.header-drawer__nav {
  display: flex;
  flex-direction: row;
  grid-column: 1;
  grid-row: 1/-1;
  width: calc(100% + 20px);

  @include tablet-max {
    left: 0;
    position: absolute;
    top: 10px;
    z-index: -1;
  }

  @include tablet{
    flex: 0 1 auto;
    flex-direction: column;
    justify-content: flex-end;
    height: calc(100% + 20px);
    width: 100%;
  }
}

:is(.header-drawer__navigation--main, .header-drawer__navigation--secondar, .header-drawer__navigation--tertiary) {
  grid-row: 1;

  &:nth-child(2) {
    grid-column: 2;
  }
  &:nth-child(3) {
    grid-column: 3;
  }
  &:nth-child(4) {
    grid-column: 4;
  }
}

.header-drawer__navigation--main {
}

.header-drawer__navigation-list--main {
  width: 100%;
  padding: 0;
}

.header-drawer__navigation--secondary {
}

.header-drawer__navigation--tertiary {
  display: flex;
  flex-direction: column;
  gap: 25px;
  min-width: 190px;

  @include tablet-max {
    display: contents;
  }
}

.header-drawer__navigation-title {
  max-width: 100%;
  padding: 0;

  @tablet {
    max-width: 190px;    
  }
}

.header-drawer__navigation-list--secondary {
  width: 100%;
  padding: 10px 0 0;
}

.header-drawer__brand{
  aspect-ratio: var(--header-logo-aspect-ratio);
  background-image: var(--drawer-brand-image-url);
  background-repeat: no-repeat;
  background-position: center left;
  background-size: contain;
  height: 100%;
  max-height: 75px;
  max-width: 190px;
  order: -1;
  width: 100%;

  a {
    display: block;
    height: 100%;
  }
}

.header-drawer__passport{
  margin: 0;

  #header-pp-username{
    @include font-family($font-heading-bold);
    font-size: 13px;
    white-space: nowrap;
    text-transform: uppercase;
    color: $header-drawer-navigation-title-text-color;

    #toggle-user-menu{
      display: none;
    }
  }

  ul{
    @extend %no-bullets;
    line-height: 24px;
    padding: 10px 0 0 0;
  }

  ul li{
    font-size: 12px;
  }

  ul li a{
    color: $header-drawer-passport-list-item-text-color;
  }
}

.header-drawer__passport-links{
  white-space: nowrap;
  margin-left: -4px;

  .fa-stack .fa-stack-1x{
    color: $header-drawer-passport-icon-link-text-color;
  }
}

.header-drawer__sites {
  padding: 0;
}


.header-drawer__navigation-list--sites{
  @include tablet{
    column-count: 2;
    column-gap: 40px;
  }
}

.header-drawer__navigation-title{
  @include font-family($font-heading-bold);
  margin: 0;
  font-size: 13px;
  text-transform: uppercase;
  padding: 0;
  font-weight: 200;
  color: $header-drawer-navigation-title-text-color;

  .header-drawer__color-scheme &{
    padding: 0 0 5px;
  }
}

.header-drawer__navigation-list{
  @extend %no-bullets;
}

.header-drawer__navigation-list--nav {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  flex: 1 1 auto;
  min-height: 40px;

  @include tablet{
    flex-direction: column;
    height: 100%;
  }
}

.header-drawer--active .header-drawer__navigation-list--nav{
  background-color: $header-drawer-navigation-list-background-color--nav-active;
}

.header-drawer__navigation-list--social{
  align-items: center;
  display: flex;
  gap: 20px;
  flex-flow: row wrap;
}

.header-drawer__navigation-list--cta{
  padding-top: 10px;
}

.header-drawer__navigation-item{
  @include tablet {
    max-width: min(22vw,190px);
  }

  a{
    color: $header-drawer-navigation-item-text-color;
  }
}

.header-drawer__navigation-item--nav,
.header-drawer--active .header-drawer__navigation-item--nav-nav{

  
  height: $header-drawer-navigation-item-height--nav;
  background-color: $header-drawer-navigation-item-background-color--nav;

  @include tablet {
    height: $header-drawer-navigation-item-height--nav-tablet;
  }

  a,
  button {
    @extend %button-reset;
    cursor: pointer;
    display: inline-block;
    text-indent: 0;
    outline: none;
    height: $header-drawer-navigation-item-height--nav;
    line-height: $header-drawer-navigation-item-line-height--nav;
    width: $header-drawer-navigation-item-width--nav;
    color: $header-drawer-navigation-item-text-color--nav;
    flex: 0 0 auto;
    position: relative;
    z-index: 30;
    border-radius: 0;

    i {
      position: absolute;
      left:0;
      width: 100%;
      text-align: center;
      top: 0px;
      line-height: $header-drawer-navigation-item-line-height--nav;
      font-size: 16px;
    }

    span {
      text-overflow: ellipsis;
    }

    @include tablet {
      height: $header-drawer-navigation-item-height--nav-tablet;
      line-height: $header-drawer-navigation-item-line-height--nav-tablet;
      width: $header-drawer-navigation-item-width--nav-tablet;

      i{
        line-height: $header-drawer-navigation-item-line-height--nav-tablet;
        font-size: 18px;
      }
    }
  }
}

.header-drawer__navigation-item--nav-nav{
  position: absolute;
  top: 0;
  left: 0;
}

.header-drawer__navigation-item--nav-nav{
  background-color: $header-drawer-navigation-item-background-color--nav-nav;
  
  a,
  button{
    color: $header-drawer-navigation-item-text-color--nav-nav;
  }
}


.header-drawer--active.header-drawer--forum .header-drawer__navigation-item--nav-forum,
.header-drawer--active.header-drawer--nav .header-drawer__navigation-item--nav-nav,
.header-drawer--active.header-drawer--dev .header-drawer__navigation-item--nav-dev,
.header-drawer--active.header-drawer--shop .header-drawer__navigation-item--nav-shop{
  background-color: $header-drawer-navigation-item-background-color--nav-active;
  
  a,
  button {
    color: $header-drawer-navigation-item-text-color--nav-active;
  
    i::before{ 
      font-family: $font-font-awesome;
      content: "\f00d";
    }
  }
}

.header-drawer__navigation-item--main{
  @include font-family($font-heading-bold);
  font-size: 22px;
  line-height: 32px;
  width: max-content;
}

.header-drawer__navigation-item--main.header-drawer__navigation-item--sub{
  font-size: 18px;
  padding-left: 10px;
}

.header-drawer__navigation-item--secondary{
  line-height: 30px;
  width: max-content;
}

.header-drawer__navigation-item--social{
}

.header-drawer__navigation-item--sites{
  line-height: 22px;
}

.header-drawer__navigation-item--forum {
  line-height: 30px;
}

.header-drawer__navigation-item--shop {
  line-height: 30px;
}

.header-drawer__navigation-item--cta{
  a{
    background-color: $header-drawer-navigation-item-background-color--cta;
    display: block;
    border-radius: 4px;
    padding: 10px 12px;
    margin-top: 10px;
    text-align: center;
    color: $header-drawer-navigation-item-text-color--cta;
  }
}

.header-drawer__navigation-item--social {
  a[class*="icon"] {
    height: 30px;
    filter: var(--drawer-icon-filter);

    [data-color-scheme=dark] {
      filter: var(--drawer-icon-filter);
    }
  }
}

.header-drawer__color-scheme{
  display: none;
  padding: 0;
}

.header-drawer__color-scheme-button{
  border-width: 0;
  border-radius: 999px;
  padding: 3px 13px 3px 13px;
  background-color: #fff;
  color: #000;
  position: relative;
  width: 8em;
  text-align: left;
  cursor: pointer;

  &:focus{
    outline: 0;
    box-shadow: none;
  }

  &:after{
    content: "";
    position: absolute;
    height: 20px;
    width: 20px;
    right: 2px;
    top: 2px;
    background-color: #c4c4c4;
    border-radius: 999px;
  }
}

.header-drawer__color-scheme-button--dark{
  background-color: #000;
  color: #fff;
  text-align: right;

  &:after{
    left: 2px;
    right: auto;
  }
}



.header-drawer__navigation--disclosures {
  grid-column: 2/4;
  grid-row: 2;

  .header-drawer__navigation-item--disclosures {
    display: inline-block;
    font-size: 9px;
    white-space: nowrap;

    &:not(:last-child):after {
      content: "|";
      margin: 0 3px 0 5px;
    }
  }
}
