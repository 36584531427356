.panels-flexible-row.background{
  position: relative;
  margin-bottom: 15px;
  @include background-full-width;

  &:before{
    background-color: $panel-row-background-color--background;
  }
}

.swipe {
  overflow: hidden;
  visibility: hidden;
  position: relative;
  margin: 20px 0 0;
}

.swipe-wrap {
  overflow: hidden;
  position: relative;
}

.swipe-wrap > div {
  float: left;
  width: 100%;
  position: relative;
  margin: 0;
  padding: 0 15px;
}

.css-swipe {

  .panels-flexible-region-inside {
    display: flex;
    flex-flow: row nowrap;
    overflow-x: scroll;
    overflow-y: hidden;
    scroll-snap-type: x mandatory;
    -webkit-overflow-scrolling: touch;
    width: var(--section-wide-max-width);

    & > div {
      flex: 1 0 auto;
      margin: 0;
      scroll-snap-align: center;
      width: calc(90vw);
    }

    section{
      margin-left: 15px;
      margin-right: 15px;
      width: auto;
    }

    @include tablet {
      display: grid;
      grid-gap: 0 30px;
      grid-template-columns: repeat(3, 1fr);
      margin: 25px 0 0;
      overflow: hidden;
      width: 100%;

      @supports (-ms-ime-align:auto) {
        display: flex;
        
        > div {
          width: calc(100% / 3 - 30px);
        }
      }

      > div,
      & .block,
      & .block__column {
        display: contents;
      }

      .contextual-links-wrapper {
        margin: 20px 0 -40px;
        position: relative;
        order: -1;
        right: 0;

        > * {
          float: right;
        }
      }

      .block__header {
        align-self: flex-end;
        order: 1;
      }

      .bullet {
        @for $i from 2 through 6 {
          &:nth-child(#{$i}) {
            order: #{$i+1};
           }
        }
      }

      .bullet--large {
        order: 2;
      }
    }
  }
}