.block-cover {
  margin: 15px 15px 0;
  width: auto;

  @include tablet {
    height: 630px;
    background-size: cover;
    background-position: center center;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding: 30px;
    position: relative;
    margin: 0 0 0 60px;
    height: $block-cover-height--tablet;
    background-image: none !important;
    flex-wrap: wrap;
    padding: 0;
  }
}

.block-cover__brand{
   /* note: auto prefixer doesn't handle image-rendering at this time */
  image-rendering: -moz-crisp-edges;
  image-rendering:   -o-crisp-edges;
  image-rendering: -webkit-optimize-contrast;
  image-rendering: crisp-edges;
  -ms-interpolation-mode: nearest-neighbor;
  
  @include tablet {
    aspect-ratio: var(--header-logo-aspect-ratio);
    background-image: url($block-cover-brand-image-url);
    background-position: left center;
    background-repeat: no-repeat;
    background-size: contain;
    height: $block-cover-brand-height;
    display: block;
    position: absolute;
    top: 15px;
    left: 30px;
    z-index: 2;
  }
}

.block-cover--title .block-cover__brand{
  @include tablet {
    aspect-ratio: var(--header-logo-aspect-ratio); 
    background-image: url($block-cover-brand-image-url--title);
    height: $block-cover-brand-height--title;
  }
}

.block-cover__panel-title,
.block-cover .block__header {
  display: none;
}

.block-cover__panel-title--visible{
  @include tablet {
    @include font-family($block-cover-panel-title-font-family);
    display: block;
    position: absolute;
    top: $block-cover-panel-title-top;
    left: 30px;
    z-index: 2;
    color: #fff;
    font-size: 30px;
  }
}
