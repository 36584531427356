html {
  background: var(--body-background-color);
  box-sizing: border-box;
  font-size: 62.5%;
  min-height: 100%;
  display: flex;
  width: 100%;
  overflow-y: scroll;

  *,
  *::before,
  *::after {
    box-sizing: inherit;
  }

  &.no-scroll {
    overflow: hidden;
    padding-right: var(--scrollbar-width);
  }
}

*::selection {
  background-color: $body-selection-background-color;
  color: $body-selection-text-color;
}

body {
  @include font-family($font-paragraph);
  background: var(--body-background-color);
  color: var(--body-text-color);
  font-size: var(--body-font-size);
  line-height: var(--body-line-height);
  display: flex;
  flex-direction: column;
  min-height: 100%;
  width: 100%;
  overflow-x: hidden;

  &.no-overflow{
    overflow-x: unset;
  }
}

a {
  color: inherit;
  text-decoration: none;

  &:hover {
    color: inherit;
    text-decoration: underline;
  }

  &.disabled-link {
    pointer-events: none;
    text-decoration: none;
  }
}

input:focus {
  &::-webkit-input-placeholder,
  &:-moz-placeholder,
  &::-moz-placeholder,
  &:-ms-input-placeholder {
    color: transparent;
  }
}

#page-wrap {
  margin: 0 auto;
  max-width: 1110px;
  position: relative;
  z-index: 2;
}

#page{
  margin-top: 10px;
}

body.panel-page #page .page-title {
  @extend .hide;
}

.page-header {
}

/* Author Page */
.view-author-archive {
  padding: 25px 0 0 0;
}

.page-search #page h1.page-title {
  @include font-family($font-paragraph);
  display: block;
}


/* Regions */
.region-site-header {
  background: var(--body-header-background-color);
  box-shadow: var(--header-top-shadow);
  display:flex;
  flex-direction: column;

  .alt-brand & {
    display: flex;
    flex-flow: row nowrap;

    .header-top-alert-bar,
    .ad-unit {
      display: none;
    }

    .header-top {
      flex: 1 1 auto;
      width: auto;
    }

    .block-topic {
      background: none;
      flex: 1 1 auto;
    }
  }
}

textarea,
input,
select {
  background-color: var(--body-background-color);
  color: var(--body-text-color);
}

.resizable-textarea .grippie{
  background-color: var(--body-grippie-color);
  border-width: 0;
}


