.header-top-inner2 {
  .alt-brand & {
    align-content: flex-start;
    flex-flow: column wrap;
    justify-content: center;
    padding-right: 60px;
  }
}

.header-top__drawer-toggle {
  .alt-brand & {
    order: 1;
  }
}


.header-top__brand {
  .alt-brand .header-top--title & {
    align-items: flex-end;
    background-image: none;
    height: 14px;
    display: flex;
    max-width: $header-top-brand-width--title-alt + 100px;
    order: 3;
    text-align: left;
    text-decoration: none;
    width: 100%;

    @at-root .vpn-hub#{&} {
      align-self: center;
      height: 16px;
    }

    @include tablet {
      height: 21px;
    }

    @at-root .alt-brand .header-top--title.header-top--scrolled .header-top__brand {
      background: none;
      width: 100%;

      @include tablet {
        height: 20px;
      }
    }

    &:before {
      @include mobile {
        content: "brought to you by";
        display: inline-block;
        font-size: 10px;
      }

      @include tablet {
        font-size: 12px;
      }
    }

    &:after {
      @include tablet {
        background-image: var(--header-top-brand-image-url--title-alt);
        background-repeat: no-repeat;
        background-size: $header-top-brand-background-size--title-alt;
        content: "";
        display: inline-block;
        height: 8px;
        margin: 0 0 3px 3px;
        width: $header-top-brand-width--title-alt;
      }

      @include tablet {
        height: 10px;
        margin: 0 0 3px 4px;
        background-size: $header-top-brand-background-size--title-alt--tablet
      }
    }
  }
}


.header-top__navigation{
  .alt-brand & {
    display: none;
  }
}


.header-top__panel-title {
  .alt-brand &,
  .alt-brand .header-top--scrolled & {
    flex: 0 0 auto;
    margin: 0 10px;
    order: 2;
    height: 20px * 1.1;
    line-height: 20px;

    @include tablet {
      font-size: 25px;
      height: 30px * 1.1;
      line-height: 30px;
    }
  }
}
