.block-topic {
  @include font-family($block-topic-font-family);
  background-color: $body-header-background-color;
  color: var(--block-topic-text-color);
  top: 0;
  padding: 0;
  position: relative; 
  width: 100%;
  display: flex;
  flex-direction: column;

  @include tablet {
    flex-direction: row;

    &:has(form, li) {
      height: var(--block-topic-height--tablet);
    }
  }
}

.block-topic__form {
  display: none;
  flex: 1 1 auto;

  @include tablet {
    display: flex;
    max-width: 470px;
  }
}

.block-topic__input {
  color: var(--block-topic-input-text-color);
  appearance: none;
  border: 0;
  background-color: transparent;
  line-height: $block-topic-input-line-height;
  height: $block-topic-input-height;
  flex: 1 0 auto;
  padding: 0 10px;
  
  &:focus {
    outline-width: 0;
  }
  
  @include tablet{
    line-height: $block-topic-input-line-height--tablet;
    height: $block-topic-input-height--tablet;
    max-width: 350px;
  }
}

.block-topic__submit {
  @extend %button-reset;
  color: inherit;
  width: $block-topic-submit-width;
  height: $block-topic-submit-height; 
  text-align: center;

  i {
    font-size: 16px;
  }

  span {  
    font-size: 0;
    position: absolute;
  }
  
  @include tablet{
    order: -3;
    height: $block-topic-submit-height--tablet;
    width: $block-topic-submit-width--tablet;
    
    i {
      font-size: 18px;
    }
  }
}

// topics list

.block-topic__topics-list {
  @extend %no-bullets;
  display: none;

  @include tablet {
    flex: 1 1 auto;
    display: block;
    overflow:hidden;
    text-align: right;
  }
}

.block-topic__topics-item {
  @include font-family($block-topic-topics-item-font-family);
  display: block;
  font-size: 15px;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  height: $block-topic-topics-item-height;
  line-height: $block-topic-topics-item-line-height;
  
  a{
    padding: 0 15px;
    color: inherit;
    display: inline-block;
    
    &:is(:focus,:hover) {
      text-decoration: none;
    }
  }
}

// nested menu items

.block-topic__topics-sub-list {
  background-color: $body-header-background-color;
  -webkit-backdrop-filter: var(--block-topic-topics-sub-list-backdrop-filter);
  backdrop-filter: var(--block-topic-topics-sub-list-backdrop-filter);
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.25);
  display: none;
  list-style: none;
  max-width: 300px;
  padding: 0;
  position: absolute;
  text-align: left;
  top: calc(100% - 5px);
  z-index: 1;

  .block-topic__topics-item:hover & {
    display: block;
  }
}

a.block-topic__topics-sub-btn {
  display: block;
  line-height: 1.21;
  padding: 15px;

  &:hover {
    background-color: $block-topic-topics-sub-item-background-color--hover;
    color: $block-topic-topics-sub-item-text-color--hover;
  }
}

// pencil ad

.block-topic__pencil-ad {
  flex: 0 1 auto;
  margin-top:0;
  margin-left:auto;
  margin-right:auto;
  min-width:100%;

  @include tablet {
    display:block;
    min-width:auto;

    #topic-pencil-rt-wrap {
      min-width:385px;
    }
  }

  #topic-pencil-rt-wrap {
    display: inline-block;
    vertical-align: top;
    min-height:60px;
  }
}



