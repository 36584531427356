.bullet {
  clear: both;
  margin-bottom: $bullet-margin-bottom;

  .article-body &{
    a{
      text-decoration: none;
    }
  }

  &.bullet--large {
    margin-bottom: $bullet-margin-bottom;
  }

  &.bullet--best{
    margin-bottom: 20px;

    @include tablet {
      margin-bottom: $bullet-margin-bottom;
    }
  }

  &.bullet--plus.bullet--cover {
    @include tablet{
      height: $block-cover-height--tablet;
      flex: 1 1 auto;
      margin: 0;
      position: relative;
      overflow:hidden;

      &::before {
        content: ' ';
        display: block;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: 1;
      }

      &:nth-child(5) {
        width: 100%;

        &::before {
          @include linear-gradient($block-cover-article-overlay-gradient--tablet-article-1);
        }
      }

      &:nth-child(6) {
        display: none;

        &::before {
          @include linear-gradient($block-cover-article-overlay-gradient--tablet-article-2);
        }
      }

      &:nth-child(7) {
        display: none;

        &:before {
          @include linear-gradient($block-cover-article-overlay-gradient--tablet-article-3);
        }
      }

      &:nth-child(8) {
        display: none;

        &::before {
          @include linear-gradient($block-cover-article-overlay-gradient--tablet-article-4);
        }
      }

      .block-cover--2-articles &,
      .block-cover--3-articles &,
      .block-cover--4-articles &{
        &:nth-child(5) {
          width: 60%;
        }

        &:nth-child(6) {
          width: 40%;
          display: block;
        }
      }
    }

    @include desktop {
      .block-cover--3-articles &,
      .block-cover--4-articles &{
        &:nth-child(5) {
          width: 40%;
        }

        &:nth-child(6) {
          width: 30%;
          display: block;
        }

        &:nth-child(7) {
          width: 30%;
          display: block;
        }
      }

      .block-cover--4-articles &{
        &:nth-child(7) {
          height: $block-cover-height--tablet * .6;
        }

        &:nth-child(8) {
          width: 30%;
          height: $block-cover-height--tablet * .4;
          display: block;
        }
      }
    }
  }
}

.bullet__link {
  display: flex;
  flex-direction: row;
  position: relative;
  align-items: flex-start;

  &:focus,
  &:hover {
    text-decoration: none;
  }

  .bullet--plus &,
  .bullet--read-next &{
    display: block;

    @include tablet{
      display: flex;
    }
  }

  .bullet.bullet--cover.bullet--plus &{
    @include tablet {
      height: 100%;
    }
  }
}

.bullet--large .bullet__link{
  display: flex;
  flex-direction: column;
}

.bullet--grid .bullet__link{
  @include tablet{
    flex-direction: column;
  }
}

.bullet__title {
  @include font-family($bullet-title-font-family);
  font-size: $bullet-title-font-size;
  margin: 0;
  color: var(--bullet-title-text-color);
  word-break: break-word;
  line-height: 1.1;

  @at-root .bullet #{&}:before{
    clear: none;
  }

  &:is(:hover, :focus) {
    color: var(--bullet-title-text-color--hover);
  }
  
  a{
    
    &:hover,
    &:focus{
      text-decoration: none;
    }
  }

  .bullet--large & {
    font-size: clamp(21px, 3vw, 30px);
  }

  .bullet--se &{
    font-size: 24px;
  }

  .bullet--se .bullet__link:hover &,
  .bullet--se .bullet__link:focus &{
    color: #00acf2;
  }

  .bullet--list & {
    font-size: clamp(18px, 3vw, 27px);
  }

  .bullet.bullet--read-next &{
    font-size: 18px;
    line-height: 1.21;
    margin: 0;
  }

  .bullet--plus.bullet--large,
  .bullet--plus.bullet--featured & {
    font-size: clamp(21px, 3vw, 30px);
  }

  .bullet--plus.bullet--list.bullet--large & {
    @include tablet{
      padding-right: 100px;
    }
  }

  .bullet--plus.bullet--list.bullet--cover & {
    @include tablet {
      @include font-family($bullet-title-font-family--cover-tablet);
      padding: 0;
      text-shadow: 1px 1px 1px rgba(#000, .16);
      color: #fff;
      font-size: 22px;
    }
  }

  .bullet--plus.bullet--list.bullet--cover.bullet--large &{
    @include tablet {
      font-size: clamp(28px, 3vw, 36px);
    }
  }

  .bullet--grid & {
    @include tablet {
      font-size: $bullet-title-font-size--grid--tablet;
    }
  }

  .bullet--sponsored & {
    &:is(:hover, :focus) {
      color: $color-brand-sponsored;
    }
  }

  .bullet.bullet--deal-embed &{
    font-size: clamp(16px, 2vw, 21px);
  }

  .article-token--best-list &{
    font-size: 18px;
    margin: 0;
  }

  .block--sidebar-promo &{
     font-size: 21px;
  }
}
 
.bullet__tag {
  @include font-family($bullet-tag-font-family);
  color: var(--bullet-tag-color);
  line-height: 1;
  margin: 0 0 5px 0;
  display: block;
  font-size: 12px;

  .bullet--se &{
    display: none;
  }

  .bullet--sponsored & {
    color: $color-brand-sponsored;
  }

  .bullet--cover & {
    @include tablet {
      color: var(--bullet-tag-text-color--cover-tablet);
    }

    @include desktop {
      font-size: 14px;
    }
  }
}

.bullet__image {
  border-radius: var(--bullet-image-border-radius);
  overflow: hidden;
  width: 33%;
  margin: 0 15px 0 0;
  flex-shrink: 0;
  flex-grow: 0;
  
  .bullet--product-image &{
    .image {
      @extend %image--contain;
      @extend %image--1x1;

      img{
      }
    }
  }

  .bullet--se &{
    border-radius: 4px;
  }

  .bullet--deal &{
    .image {
      @extend %image--cover;
      @extend %image--1x1;
    }
  }

  .bullet--best &{
    .image {
      @extend %image--1x1;
      @extend %image--contain;
    }
  }

  .bullet--read-next &{
    .image {
      @extend %image--4x3;
      @extend %image--cover;
    }
  }

  .bullet--deal-block &,
  .bullet--best &{
    @include tablet{
      width: 125px;
    }
  }

  .bullet--deal-embed &{
    width: 100px;

    @include tablet{
      width: 100px;
    }
  }

  .bullet.bullet--plus.bullet--cover & {

    .image {
      @extend %image--cover;
    }

    @include tablet{
      border-radius: 0;
      object-fit: cover;
      position: absolute;
      width: 100%;
      height: 100%;
      margin: 0;
      z-index: 0;

      .image {
        &,
        & img {
          height: 100%;
        }
      }
    }
  }

  .article-token--best-list &{
    .image{
      @include tablet {
        padding-top: 150px;
      }

      @include desktop {
        padding-top: 120px;
      }
    }
  }
}

.bullet--grid .bullet__image {
  @include tablet{
    width: 100%;
    margin: 0 0 10px;
  }
}

.bullet--list .bullet__image {
  width: clamp(25%, 30vw, 220px);
}

.bullet--plus .bullet__image,
.bullet--read-next .bullet__image{
  order: 1000;
  float: right;
  margin: 0 0 20px 20px;

  @include tablet{
    margin: 0 0 0 30px;
  }
}

.bullet--read-next .bullet__image{
  max-width: 150px;
  margin-left: 20px;
  width: 33%;
}

.bullet--large .bullet__image,
.bullet--large.bullet--list .bullet__image,
.bullet--large.bullet--plus .bullet__image {
  order: unset;
  width: 100%;
  margin: 0 0 10px;
}

.bullet--featured .bullet__image {
  @include tablet{
    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: -13px;
      width: 13px;
      height: 30px;
      display: block;
      background-color: $bullet-featured-image-background-color;
    }
  }
}

.bullet--sponsored .bullet__image .image{
  position: relative;
  
  &:after{
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border: 3px solid $color-brand-sponsored;
  }
}

.bullet__video{
  margin: 0 0 10px;
  order: unset;
  width: 100%;
}

.bullet__body{
  flex: 1 1 0%;

  .bullet--best & {
    display: flex;
    flex-direction: column;
    min-width: 0;
  }

  // .bullet.bullet--plus.bullet--cover &{
  //   position: relative;
  // }

  .bullet.bullet--cover.bullet--plus & {
    @include tablet{
      padding: 30px;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-self: stretch;
      position: relative;
      z-index: 1;
    }
  }
}

.bullet__description {
  @include font-family($bullet-description-font-family);
  order: 1;
  word-break: break-word;
  display: none;
  margin: 10px 0 15px;
  font-size: clamp(16px, 2vw, 18px);
  line-height: 1.31;

  @include tablet{
    display: block;
  }

  a,
  .bullet__description_link {
    color: $bullet-description-link-text-color;

    &:is(:hover, :focus){
      text-decoration: none;
    }
  }

  .bullet--se &{
    color: #fff;
    font-size: 15px;
    line-height: 1.35;
    display: block;
  }

  .bullet--plus &{
    display: none;

    @include tablet{
      display: block;
    }
  }

  .bullet--read-next &{
    display: block;
    font-size: 16px;
    margin: 10px 0;
  }

  .bullet--plus.bullet--featured &{
    display: block;

    @include tablet {
      padding-right: 100px;
    }
  }

  .bullet--best &{
    strong{
      @include font-family($font-heading-semibold);
    }
  }

  .bullet--best &,
  .bullet--deal-block &{
    margin: 5px 0 0 0;
    display: block;
  }

  .bullet--deal-block &{
    display: none;

    @include tablet{
      display: block;
    }
  }

  .bullet--deal-embed &{
    display: block;
    margin: 0;
    font-size: 18px;
    line-height: 1.2;
  }

  .bullet--sponsored &{
    a,
    .bullet__description_link {
      color: $color-brand-sponsored;
      @include font-family($font-paragraph-bold);
    }
  }

  .bullet--best &{
    display: none;

    @include tablet{
      display: block;
    }
  }

  .article-token--best-list &{
    font-size: 13px !important;
    display: block;
    margin-top: 10px;
    margin-bottom: 0;

    @include tablet{
       font-size: 13px !important;
       margin-bottom: 25px;
    }

    a{
      color: $bullet-description-link-text-color--best-list;
    }
  }
}

.bullet__summary {
  @include font-family($font-paragraph-light);
  font-size: $bullet-summary-font-size;
  color: $bullet-summary-color;
  margin: 5px 0 0;
  padding: 0;

  .article-token--best-list &,
  .bullet--best & {
    margin-top: 0;
  }

  .bullet--deal-embed &{
    margin: 2px 0 0;
  }
}

.bullet--grid .bullet__summary,
.bullet--large .bullet__summary{
  text-align: $bullet-title-text-align--large;
}

.bullet__price{
  font-size: 16px;

  &.bullet__price--grid-cta{
    color: #f00;
    @include font-family($font-paragraph-bold);
  }

  &.bullet__price--current{
    @include font-family($font-paragraph-bold);
    color: #f00;
  }

  &.bullet__price--regular{
    color: #8d8d8d;
    text-decoration: line-through;
  }

  .bullet--deal-embed &{
    font-size: 18px;
  }
}

.bullet__savings{
  @include font-family($font-paragraph-bold);
  font-size: 16px;
  color: #f00;
  
  @include tablet{
    display: block;
  }

  .bullet--deal-embed &{
    font-size: 18px;
    
    @include tablet{
      display: inline;
    }
  }
}

.bullet__author{
  padding-right: 10px;

  .bullet--best & {
    font-weight: 900;
    color: #111;
    font-style: italic;
  }
}

.bullet__time{
  padding-right: 10px;

  .bullet--best & {
    &:before{
      content: "Last updated ";
    }

    color: $bullet-time-text-color--best;
  }

  .bullet--deal &{
    display: block;

    @include tablet{
      display: none;
    }
  }

  .article-token--best-list & {
    color: $bullet-time-text-color--best-list;
  }
}

.bullet__comment-count{
  cursor: pointer;
  
  i{
    margin-right: 5px;
  }

  .bullet--best & {
    display: none;
  }
}

.bullet__read-more{
  @include link-underline;
  text-decoration-thickness: 1px;
}