.btn--read-more {
  @include font-family($btn-read-more-font-family);
  cursor: pointer;
  display: block;
  font-size: 16px;
  width: 100%;
  padding: 0;
  margin-bottom: 20px;
  position: relative;
  text-align: center;

  &:before {
    position: absolute;
    content: "";
    top: 15px;
    height: 1px;
    border-top: 1px solid $btn-read-more-border-color;
    width: 100%;
    left: 0;
  }

  span {
    display: inline-block;
    position: relative;
    z-index: 1;
    margin: 0 auto;
    padding: 5px 10px;
    background-color: $btn-read-more-background-color;
    color: $btn-read-more-text-color;
  }

  &:is(:focus, :hover) {
    span {
      background-color: darken($btn-read-more-background-color, 5%);
    }
  }

  .block--best &,
  .block--deals &{
    position: absolute;
    margin-top: 4px;

    &:before{
      display: none;
    }

    span{
      @include font-family($font-heading-bold);
      border-radius: 4px;
      font-size: 12px;
      letter-spacing: 1.2px;
      padding: $btn-read-more-padding--best;
      text-transform: uppercase;
      background-color: $color-brand-primary;
      color: $color-brand-primary-text;
    }

    @include tablet {
      display: none;
    }
  }
}