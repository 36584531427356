// BT

$article-body-reco-block-border-radius: 25px !default;
$article-body-reco-block-border-width: 5px !default;
$article-image-border-radius: 10px !default;
$block-cover-brand-height: 50px !default;
$body-container-border-radius: 10px !default;
$body-container-border-width: 2px !default;
$bullet-description-font-family: $font-paragraph-medium !default;
$bullet-image-border-radius: 5px !default;
$cta-border-radius: 25px !default;
$drawer-brand-image-url: '#{$site-images-path}/#{$site}-logo-wide--white.svg' !default;
$gallery-gap: 5px !default;
$header-logo-aspect-ratio: 3 !default;
$header-logo-vertical-margin--tablet: 7px !default;
$header-drawer-navigation-item-text-color--nav: $color-brand-primary-text !default;
$header-drawer-navigation-item-text-color--nav-nav: $color-brand-primary-text !default;
$header-drawer-navigation-item-text-color--nav-active: $color-brand-primary-text !default;
$header-drawer-navigation-item-text-color--cta: $color-brand-primary-text !default;
$header-top-drawer-toggle-text-color: $color-brand-primary-text !default;
$hr-thickness: 3px !default;
$quick-shop-subtitle-font-family: $font-paragraph-medium !default;
$quick-shop-subtitle-text-color: $color-brand-secondary !default;
$quote-pull-border-radius: 10px !default;
$quote-pull-border-width: 10px !default;
$quote-pull-padding: 15px !default;
$quote-pull-padding--left: 15px !default;
$quote-pull-padding--right: 15px !default;
$rating-full-border-radius: 20px !default;
$rating-mini-border-radius: 10px !default;
$testimonial-border-width: 5px !default;
$versus-dot-bg: $color-brand-primary !default;
$versus-dot-text-color: $color-brand-primary-text !default;


