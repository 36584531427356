// Reviews Blocks General Styling

.block--reviews {
}

.bullet.bullet--review {
  display: flex;
  flex-direction: column;
  margin: 25px 0 50px;

  .bullet__title {
    font-size: 24px;
    line-height: 28px;
    margin: 10px auto;
    min-width: 200px;
    position: relative;

    @at-root .bullet.bullet--review[class*="node-rating"] .bullet__title {
      @include rating-stars;

      &:before,
      &:after {
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }

  .bullet__description {
    display: block;
    order: initial;
  }

  .bullet__good-bad-list {
    list-style: none;
    margin: 0 0 25px;
    padding: 0;

    li {
      font-size: 16px;
      line-height: 1.21;
      margin: 0 0 10px;
      padding-left: 25px;
      position: relative;

      &:before {
        content: "\f067";
        color: #30ce8b;
        font-family: $font-font-awesome;
        font-weight: 600;
        left: 0;
        line-height: 10px;
        position: absolute;
        top: 5px;
      }
    }
  }

  .bullet__good-bad-list--bad li:before {
    content: "\f068";
    color: #ce6d30;
  }

  .bullet__cta {
    @include font-family($font-heading-bold);
    background: $color-brand-secondary;
    border-radius: 4px;
    color: $color-brand-secondary-text;
    padding: 0px 40px;
    text-align: center;
    text-decoration: none;
    line-height: 40px;

    &:hover {
      background: $color-brand-primary;
      color: $color-brand-primary-text;
    }
  }
}

// Reviews Block Wide (3 across)

.block--reviews-wide {

  @include tablet {
    .block__column {
      display: grid;
      grid-gap: 0 30px;
      grid-template-columns: 1fr 1fr 1fr;
      width: 100%;
    }
  }

  .bullet--review {

    @include tablet {
      display: contents;

      .bullet__image {
        order: 1;
      }

      .bullet__link,
      .bullet__body {
        display: contents;
      }

      .bullet__title {
        align-self: center;
        order: 2;
      }

      .bullet__description {
        order: 3;
      }

      .bullet__good-bad {
        display: contents;
      }

      .bullet__good-bad-list--good {
        margin-top: 10px;
        order: 4;
      }

      .bullet__good-bad-list--bad {
        order: 5;
      }

      .bullet__cta {
        align-self: flex-start;
        justify-self: center;
        order: 6;
      }
    }
  }

  .bullet__link {
    flex-direction: column;
  }

  .bullet__image {
    width: 100%;

    @at-root .block--reviews-wide .bullet--review-product-image {

      .bullet__image {
        height: calc(100vw - 30px);
        max-height: 300px;

        figure.image {
          height: 100%;
        }

        img {
          object-fit: contain;
          height: 100%;
          width: 100%;
        }
      }
    }
  }


  .bullet__body {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .bullet__title {
    text-align: center;
  }

  .bullet__cta {
    align-self: center;
  }
}

// Reviews Block Wide Pro / Con Lists

.block--reviews-wide {

  .bullet__good-bad-list {
    margin: 0 auto 25px;
    max-width: 300px;
    width: 100%;
  }
}

// Reviews Block List

.block--reviews-list {

  .bullet--review {
    margin: 25px auto 50px;
    max-width: 1000px;

    @include mobile {
      align-items: flex-start;
      display: grid;
      grid-gap: 0 20px;
      grid-template-areas:
        "image summary"
        "title summary"
        "cta summary";
      grid-template-columns: 250px auto;
    }

    @include tablet {
      grid-gap: 0 30px;
      grid-template-areas:
        "image summary cta"
        "title summary cta";
      grid-template-columns: 200px auto 250px;
    }
  }

  .bullet__image {
    grid-area: image;
    width: 100%;

    img {
      margin: 0 auto;
      max-height: 150px;
      max-width: 100%;
      width: auto;
    }
  }

  .bullet__link,
  .bullet__body {
    display: contents;
  }

  .bullet__title {
    break-after: column;
    grid-area: title;
    text-align: center;

    @include tablet {
      margin-bottom: 0;
    }
  }

  .bullet__description,
  .bullet__good-bad{
    grid-area: summary;
    margin: 0 0 25px;

    @include tablet {
      margin-bottom: 0;
    }
  }

  .bullet__cta {
    grid-area: cta;
    justify-self: center;
  }
}

// Reviews Block List Pros Cons

.block--reviews-list {

  .bullet__good-bad {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
  }

  .bullet__good-bad-list {
    flex: 1 0 calc(50% - 15px);
    min-width: 200px;

    @at-root .block--reviews-list .bullet--review-pros-cons {
      .bullet__good-bad-list--good {
        margin-right: 15px;
      }
    }
  }

  .bullet--review-pros {
    max-width: 850px;
  }
}