figure.image {
  display: block;
  position: relative; 
  margin: 0;
  
  img {
    display: block;
    height: auto; 
    width: 100%;
  }
}