.header-top {
  @include font-family($font-paragraph-book);
  color: var(--header-top-text-color);
  height: var(--header-top-height);
  order:-100;

  @include tablet {
    order:0;
    height: $header-top-height--tablet;
  }
}

.header-top-inner {
  border-bottom: var(--header-top-bottom-border);
  height: var(--header-top-height);
  z-index: 120;
  width: 100%;
  position: relative;

  @include tablet {
    height: var(--header-top-height--tablet);
  }
  
  .header-top--scrolled & {
    -webkit-backdrop-filter: var(--body-backdrop-filter);
    backdrop-filter: var(--body-backdrop-filter);
    background: var(--header-top-background-color--scrolled);
    box-shadow: var(--header-top-shadow);
    transition: top 0.2s ease-in-out;
    top: calc(-1 * var(--header-top-height));
    will-change: transform;

    @include tablet {
      top: calc(-1 * var(--header-top-height--tablet));
    }
  }
  
  .header-top--scrolled-visible & {
    top: 0;

    @include tablet {
      top: 0;
    }
  }

  .shop-bar--visible & {
    top: calc(-1 * var(--header-top-height));

    @include tablet {
      top: calc(-1 * var(--header-top-height--tablet));
    }
  }

}

.header-top-inner2 {
  display: flex;
  width: 100%;
  height: $header-top-height;
  justify-content: space-between;
  z-index: initial;
  overflow: hidden;
  position: relative;

  @include tablet {
    height: var(--header-top-height--tablet);
  }
}

.header-top-inner,
.header-top-inner2 {
  .header-top--scrolled & {
    height: var(--header-top-height--scrolled);
    padding-top: 0;
    position: fixed;

    @include tablet {
      height: var(--header-top-height--tablet-scrolled);
    }

    .no-scroll & {
      padding-right: var(--scrollbar-width);
    }
  }

}

.header-top__drawer-toggle {
  @extend %button-reset;
  aspect-ratio: 1;
  cursor: pointer;
  background-color: var(--header-top-drawer-toggle-background-color);
  outline: none;
  height: 100%;
  color: var(--header-top-drawer-toggle-text-color);
  position: relative;
  width: var(--header-top-drawer-toggle-width);
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;

  i {
    font-size: 16px;
  }

  @include tablet {

    i {
      font-size: 18px;
    }
  }

  .header-top--scrolled & {

    i {
      font-size: 16px;
    }
  }
}

.header-top__brand {
  aspect-ratio: var(--header-logo-aspect-ratio);
  background-image: var(--header-top-brand-image-url);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  height: calc(100% - var(--header-logo-vertical-margin) * 2);
  left: 50%;
  margin: var(--header-logo-vertical-margin) 0;
  position: absolute;
  transform: translate(-50%, 0);
   /* note: auto prefixer doesn't handle image-rendering at this time */
  image-rendering: -moz-crisp-edges;
  image-rendering:   -o-crisp-edges;
  image-rendering: -webkit-optimize-contrast;
  image-rendering: crisp-edges;
  -ms-interpolation-mode: nearest-neighbor;

  .header-top--title &,
  .header-top--scrolled.header-top--title & {
    margin: 0 10px;
    position: relative;
    left: 0;
    transform: none;
    background-image: var(--header-top-brand-image-url--title);
    background-size: contain;
  }

  @include tablet {
    height: calc(100% - var(--header-logo-vertical-margin--tablet) * 2);
    margin: var(--header-logo-vertical-margin--tablet) 0;
  }

}

.header-top__navigation{
  white-space: nowrap;
  flex-shrink: 0;
}


.header-top__navigation-list{
  @extend %no-bullets;
  position: relative;
  height: 100%;
}

.header-top__navigation-item {
  display: block;
  float: left;
  height: 100%;
  vertical-align: top;
  display: none;
  font-size: $header-top-navigation-item-font-size;

  @include tablet{
    display: inline-block;
  }

  .header-top--scrolled {
    a,
    button{
      height: $header-top-navigation-item-height--scrolled;
      line-height: $header-top-navigation-item-line-height--scrolled;
      width: $header-top-navigation-item-width--scrolled;

      i {
        font-size: 16px;
        line-height: $header-top-navigation-item-line-height--scrolled;
      }
    }
  }
}


.header-top__navigation-link,
.header-top__search-button{
  @extend %button-reset;
  align-items: center;
  aspect-ratio: 1;
  display: flex;
  color: var(--header-top-navigation-item-text-color);
  height: 100%;
  justify-content: center;

  span {
    display: none;
  }

  &:is(:focus,:hover) {
    color: var(--header-top-navigation-item-text-color--hover);
    background-color: var(--header-top-navigation-item-background-color--hover);
    text-decoration: none;
  }
  @include tablet {
    .header-top:not(.header-top--scrolled) & {
      i {
        font-size: 18px;
      }
    }
  }
}

.header-top__search-button{
  color: var(--header-top-navigation-item-text-color--search);

  &:is(:focus,:hover) {
    color: var(--header-top-navigation-item-text-color--search-hover);
  }
}

.header-top__navigation-item--text {
  a {
    width: auto;

    &:is(:focus,:hover) {
      text-decoration: $header-top-navigation-item-text-decoration--text-hover;
    }
  }
}

.header-top__navigation-item--search{
  display: inline-block;
}