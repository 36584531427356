.swal-overlay .detective--splash,
.swal-overlay .detective--instructions {
  @include font-family($font-paragraph);
  animation: none;
  background-color: #1F1F22;
  color: #fff;
  font-size: 16px;
  width: 600px;
}

.detective--splash,
.detective--instructions{  
  
  .swal-title {
    @include font-family($font-heading-bold);
    color: #fff;
    margin-top: 13px;
  }

  .swal-text {
    text-align: center;
    color: #fff;
  }

  .swal-button-container{
    margin: 0;
  }

  .swal-button {
    @include font-family($font-heading-bold);
    background-color: $color-brand-primary;
    color: $color-brand-primary-text;

    &:hover,
    &:active{
      background-color: $color-brand-primary;
      color: $color-brand-primary-text;
    }

    &:focus{
      box-shadow: none;
    }

    &.swal-button--cancel {
      @include font-family($font-paragraph);
      background-color: transparent;
      color: #aaa;
    }
  }
}

.detective--splash {
  &:before {
    background-image: url($detective-splash-background-image);
    content: '';
    width: 100%;
    height: 300px;
    display: block;
    background-repeat: no-repeat;
    background-position: center bottom;
    background-size: contain;
    border-radius: 5px 5px 0 0;
  }

  .swal-footer{
    display: flex;
    flex-direction: column;
    text-align: unset;
  }

  .swal-button-container:first-child{
    order:2;
  }
}

.detective--instructions{
  .swal-content{
    padding: 0;
  }

  .swal-footer{
    margin-top: 0;
  }
}

.detective__container {
  display: flex;
}

.detective__blockers {
  padding: 0;
  flex: 0 0 36%;
  list-style-type: none;
  text-align: left;
  margin: 0;
}

.detective__blockers li {
  @include font-family($font-heading-bold);
  padding: 13px 10px 10px;
  font-size: 1.1em;
  padding-left: 20px;
}

.detective__blockers li img {
  max-height: 1.5em;
  margin-right: 13px;
  vertical-align: middle;
  margin-top: -3px;
}

.detective__blockers li.active {
  background-color: #25272B;
}

.detective__blockers li:hover {
  cursor: pointer;
}

.detective__instructions {
  margin: 0;
  flex: 0 0 64%;
  padding: 13px 20px 8px 40px;
  text-align: left;
  overflow-y: auto;
  background-color: #25272B;
}

.detective__instructions li {
  padding: 0 10px 5px;
  line-height: 1.3;
}


.detective__instructions li img {
  max-height: 1em;
  vertical-align: top;
}

