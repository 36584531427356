.btn--load-more {
  background-color: transparent;
  color: $btn-load-more-text-color;
  cursor: pointer;
  display: block;
  font-size: .875em;
  margin: $btn-load-more-margin;
  text-align: center;
  width: 100%;
  padding: 10px 0 20px;
  height: 40px;
  overflow: visible;

  @include tablet{
    margin: 10px auto 20px;
  }

  span{
    font-size: 0;
    display: block;
    width: 100%;
    border-top: 2px solid $btn-load-more-border-color;
    position: relative;
    height: 20px;
  }

  span::after{
    content: '\f0d7';
    width: 40px;
    height: 40px;
    text-align: center;
    line-height: 40px;
    border: 0;
    color: #fff;
    background-color: $btn-load-more-border-color;
    border-radius: $btn-load-more-border-radius;
    font-weight: 600;
    font-family: $font-font-awesome;
    position: absolute;
    top: -100%;
    bottom: 0;
    left: 50%;
    margin-left: -20px;
    margin-bottom: -19px;
    z-index: 10;
    font-size: 20px;
  }

  i {
    display: none;
  }

  .block &{
    display: none;
  }

  .block--last &{
    display: block;
  }

  .block--best &,
  .block--deals & {
    position: absolute;
    padding: 0;
    margin: 0;

    &:before {
      display: none;
    }

    span {
      @include font-family($font-heading-bold);
      border-radius: 4px;
      font-size: 12px;
      letter-spacing: 1.2px;
      padding: $btn-load-more-padding--best;
      text-transform: uppercase;
      width: auto;
      display: inline-block;
      border-width: 0;
      background-color: $color-brand-primary;
      color: $color-brand-primary-text;
      height: auto;

      &:after{
        display: none;
      }
    }

    @include tablet{
      display: block;
    }
  }
}

.btn--load-more-gray {
  background-color: #d1d3d8;
  color: #626262;

  &:focus,
  &:hover {
    background-color: lighten(#d1d3d8, 5%);
  }
}

.btn--load-more--active {
  span {
    display: none;
  }

  i {
    display: inline-block;
  }

  .block--best &,
  .block--deals &{
    span {
      display: none;
    }

    i {
      display: inline-block;
    }
  }
}

.btn--load-more--disabled {
  display: none !important;
}