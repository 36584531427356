.background-link {
  color: inherit;
  display: inline-flex;
  overflow: hidden;
  padding: 3px 2px;
  position: relative;
  transition: color .1s ease;

  &::before {
    content: '';
    position: absolute;
    top: -4px;
    bottom: 4px;
    left: 0;
    right: 0;
    background-color: #fcd900;
    z-index: -1;
    transform: translateY(100%);
    transition: transform .2s ease;
  }

  &:hover,
  &:focus {
    color: #000;
    text-decoration: none;

    &::before {
      transform: translateY(4px);
    }
  }
}
