.footer {
  background-color: #2e2e2e;
  color: #fff;
  overflow: hidden;
  padding: 15px 0;

  .container {
    display: flex;
    flex-wrap: wrap;
    gap: 50px;
    max-width: var(--section-wide-desktop-wide-max-width);

    @include tablet {
      flex-wrap: nowrap;
    }
  }

  .shop-bar--visible & {
    @include mobile-max {
      padding-bottom: 150px;
    }

    @include mobile-to-tablet {
      padding-bottom: 120px;
    }
  }
}
.footer__brand-links {
  margin-right: auto;
}

.footer__logo {
  align-self: center;
  aspect-ratio: var(--header-logo-aspect-ratio);
  background-image: url($block-cover-brand-image-url);
  background-position: left center;
  background-repeat: no-repeat;
  background-size: contain;
  display: block;
  font-size: 0;
  height: $block-cover-brand-height;
  margin: 0 0 25px;
}

.footer__social {
  display: flex;
  flex-flow: row wrap;
  gap: 20px 30px;
  list-style: none;
  margin: 0;
  padding: 0;

  @include tablet {
    max-width: 250px;
  }
}

.footer__social-item {

  a {
    height: 25px;
    filter: invert(1);
  }
}

.footer__links {
  display: flex;
  flex-flow: row wrap;
  gap: 50px;

  @include tablet {
    flex-wrap: nowrap;
  }
}

.footer__nav {
  list-style: none;
  margin: 0;
  padding: 0;
}

.footer__nav-item {
  @include font-family($font-heading-light);
  font-size: 14px;
  opacity: 0.8;

  .disabled-link {
    border-bottom: 1px solid;
    display: block;
    margin-bottom: 5px;
  }
}

.footer .block-newsletter {
  margin: 0;

  @include tablet {
    max-width: 250px;
  }

  h3 {
    display: none;
  }

  .block-newsletter__message,
  .block-newsletter__error {
    font-size: 14px;
    margin: 0 0 10px;
    opacity: 0.8;
  }

  .block-newsletter__input {
    margin: 10px 0;
  }

  .block-newsletter__pledge {
    opacity: 0.5;
  }
}