.tag {
  @include font-family($tag-font-family);
  color: $tag-color;
  display: block;
  font-size: 11px;
  text-transform: uppercase;
}

.tag--featured {
  font-size: 12px;
}

.tag--small {
  font-size: 11px;
}

