
// Common - Utilities
//------------------------------------------------------------------------------

/* Hide visually but not from screen readers */
.hide {
  position: absolute !important;
  left: -9999em !important;
}

/* Image Replacement */
.ir {
  overflow: hidden;
  text-indent: -999em;
  font-size: 0;
}

.accessibility-head {
  @extend .hide;
}

.hidden {
  display: none !important;
}

@include tablet {
  .hidden--tablet {
    display: none !important;
  }
}

@include desktop {
  .hidden--desktop {
    display: none !important;
  }
}

@include desktop-wide {
  .hidden--desktop-wide {
    display: none !important;
  }
}

.visible {
  display: block !important;
}

@include tablet {
  .visible--tablet {
    display: block !important;
  }
}

@include desktop {
  .visible--desktop {
    display: block !important;
  }
}

@include desktop-wide {
  .visible--desktop-wide {
    display: block !important;
  }
}
