// Mixins

@mixin mobile-max {
  @media only screen and (max-width: #{$break-mobile - 1}px) {
    @content;
  }
}
@mixin mobile {
  @media only screen and (min-width: #{$break-mobile}px) {
    @content;
  }
}
@mixin mobile-to-tablet {
  @media only screen and (min-width: #{$break-mobile}px) and (max-width: #{$break-tablet - 1}px) {
    @content;
  }
}
@mixin tablet-max {
  @media only screen and (max-width: #{$break-tablet - 1}px) {
    @content;
  }
}
@mixin tablet {
  @media only screen and (min-width: #{$break-tablet}px) {
    @content;
  }
}
@mixin tablet-to-desktop {
  @media only screen and (min-width: #{$break-tablet}px) and (max-width: #{$break-desktop - 1}px) {
    @content;
  }
}
@mixin tablet-to-desktop-wide {
  @media only screen and (min-width: #{$break-tablet}px) and (max-width: #{$break-desktop-wide - 1}px) {
    @content;
  }
}
@mixin desktop-max {
  @media only screen and (max-width: #{$break-desktop - 1}px) {
    @content;
  }
}
@mixin desktop {
  @media only screen and (min-width: #{$break-desktop}px) {
    @content;
  }
}
@mixin desktop-to-desktop-wide {
  @media only screen and (min-width: #{$break-desktop}px) and (max-width: #{$break-desktop-wide - 1}px) {
    @content;
  }
}
@mixin desktop-to-desktop-ultrawide {
  @media only screen and (min-width: #{$break-desktop}px) and (max-width: #{$break-desktop-ultrawide - 1}px) {
    @content;
  }
}
@mixin desktop-wide-max {
  @media only screen and (max-width: #{$break-desktop-wide - 1}px) {
    @content;
  }
}
@mixin desktop-wide {
  @media only screen and (min-width: #{$break-desktop-wide}px) {
    @content;
  }
}
@mixin desktop-wide-to-desktop-ultrawide {
  @media only screen and (min-width: #{$break-desktop-wide}px) and (max-width: #{$break-desktop-ultrawide - 1}px) {
    @content;
  }
}
@mixin desktop-ultrawide-max {
  @media only screen and (max-width: #{$break-desktop-ultrawide - 1}px) {
    @content;
  }
}
@mixin desktop-ultrawide {
  @media only screen and (min-width: #{$break-desktop-ultrawide}px) {
    @content;
  }
}

@mixin desktop--sidebar {
  .article--centered & {
    @include tablet {
      @content;
    }
  }
  .article--sidebar & {
    @include desktop {
      @content;
    }
  }
}

@mixin desktop--sidebar-max {
  .article--centered & {
    @include tablet-max {
      @content;
    }
  }
  .article--sidebar & {
    @include desktop-max {
      @content;
    }
  }
}

@mixin desktop-wide--sidebar {
  .article--centered & {
    @include tablet {
      @content;
    }
  }
  .article--sidebar & {
    @include desktop-wide {
      @content;
    }
  }
}

// container queries

@mixin article-container-query($size) {
  @supports not (container-type: size) {
    @if $size < $break-mobile {
      @include mobile {
        @content;
      }
    }
    @else if $size < $break-desktop {
      @include desktop--sidebar {
        @content;
      }
    }

    @else {
      @include desktop {
        @content;
      }
    }
  }

  @container (min-width: #{$size}px) {
    @content;
  }
}

@mixin article-container-query-max($size) {
  @supports not (container-type: size) {
    @if $size < $break-mobile {
      @include mobile-max {
        @content;
      }
    }

    @else if $size < $break-tablet {
      @include desktop--sidebar-max {
        @content;
      }
    }

    @else {
      @include desktop-max {
        @content;
      }
    }
  }

  @container (max-width: #{$size}px) {
    @content;
  }
}

// Use with @extend %clearfix;
%clearfix {
  &::after {
    clear: both;
    content: "";
    display: table;
  }
}

// Container box

@mixin article-container {
  @extend %clearfix;
  background-color: var(--body-container-background-color);
  border-color: var(--body-container-border-color);
  border-style: solid;
  border-width: var(--body-container-border-width);
  border-radius: var(--body-container-border-radius);
  padding: var(--body-container-padding-top) var(--body-container-padding-sides) var(--body-container-padding-bottom);
  position: relative;

  *:first-child,
  h1:first-of-type,
  h2:first-of-type,
  h3:first-of-type,
  h4:first-of-type {
    margin-top: 0;
    padding-top: 0;

    &:before {
      clear: none;
    }
  }

  >*:last-child {
    margin-bottom: 0;
    padding-bottom: 0;
  }
}

@mixin article-container-wide {
  @include background-full-width;
  background: none;
  border-width: 0;
  padding-left: 0;
  padding-right: 0;

  &::before {
    background-color: var(--body-container-background-color);
    border-color: var(--body-container-border-color);
    border-width: var(--body-container-border-width) 0;
  }

  >* {
    position: relative;
  }
}

//Remove bullets from the list
%no-bullets {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

%absolute-fill {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}

%circle {
  border-radius: 999px;
}

%button-reset {
  appearance: none;
  background: none;
  border: 0;
  margin: 0;
  padding: 0;

  &:focus {
    outline: 0;
  }

  &:hover {
    cursor: pointer;
  }
}

@mixin full-width{
  body.no-overflow &{
    width: 100vw;
    max-width: calc(100vw - var(--scrollbar-width))
  }
}

@mixin mobile-full-width{
  max-width: calc(100% + 30px);
  width: calc(100% + 30px);

  @include tablet{
    width: 100%;
    max-width: none;
  }
}

@mixin background-full-width {
  position: relative;
  z-index: 1;
  
  &::before{
    content: "";
    position: absolute;
    z-index: 0;
    top: 0;
    left: 50%;
    right: 100%;
    bottom: 0;
    height: 100%;  
    width: 100vw;
    transform: translateX(-50%);
  }

  body.no-overflow.ct_wallpaper &::before{
    max-width: calc(100% + 30px) !important;
    margin-left: -15px !important;
    transform: none;
  }
}

%heading-line {
  position: relative;

  &:before,
  &:after {
    content: "";
    height: 2px;
    left: 0;
    position: absolute;
  }

  &:before {
    background-color: $heading-line-accent-color;
    width: 100%;
    z-index: 1;
  }
  
  &:after {
    background-color: rgba(170,170,170,0.15);
    right: 0;
    z-index: 0;
  }

  @include mobile{
    &:before{
      box-shadow: 0 0 5px rgba($heading-line-accent-color, 0.25);
      width: 150px;
    }
  }
}

%spin {
  -webkit-animation: fa-spin 2s infinite linear;
          animation: fa-spin 2s infinite linear;
}

// Font Familys

@mixin font-family($fonts, $important: false, $append-html: false) {
  @if map_has_key($font-map, first($fonts)) {
    font-family: map-deep-get($font-map, first($fonts), 'font-family'), remove-nth($fonts, 1) if($important,  !important, null);
    font-weight: map-deep-get($font-map, first($fonts), 'font-weight') if($important,  !important, null);
    letter-spacing: map-deep-get($font-map, first($fonts), 'letter-spacing') if($important,  !important, null);
    line-height: map-deep-get($font-map, first($fonts), 'line-height') if($important,  !important, null);

    @if $append-html==true {
      @at-root .font-loaded--#{to-class(first($fonts))}#{&} {
        font-family: map-deep-get($font-map, first($fonts), 'font-family'), remove-nth($fonts, 1) if($important,  !important, null);
        font-weight: map-deep-get($font-map, first($fonts), 'font-weight') if($important,  !important, null);
        letter-spacing: map-deep-get($font-map, first($fonts), 'letter-spacing') if($important,  !important, null);
        line-height: map-deep-get($font-map, first($fonts), 'line-height') if($important,  !important, null);
      }
    }

    @else {
      .font-loaded--#{to-class(first($fonts))} & {
        font-family: map-deep-get($font-map, first($fonts), 'font-family'), remove-nth($fonts, 1) if($important,  !important, null);
        font-weight: map-deep-get($font-map, first($fonts), 'font-weight') if($important,  !important, null);
        letter-spacing: map-deep-get($font-map, first($fonts), 'letter-spacing') if($important,  !important, null);
        line-height: map-deep-get($font-map, first($fonts), 'line-height') if($important,  !important, null);
      }
    }
  }

  @else {
    font-family: $fonts if($important,  !important, null);
  }
}

@mixin font-face($name, $path, $style: normal, $weight: normal, $exts: woff2 woff otf) {
  $src-eot: null;
  $src-all: null;
  
  $ext-append: (
    eot: "?iefix",
    svg: "#" + str-replace($name, " ", "_")
  );
  
  $formats: (
    eot: "embedded-opentype",
    otf: "opentype",
    ttf: "truetype",
  );
  
  @each $ext in $exts {
    $extmod: if(map-has-key($ext-append, $ext), $ext + map-get($ext-append, $ext), $ext);
    $format: if(map-has-key($formats, $ext), map-get($formats, $ext), $ext);
    $src-all: append($src-all, url(quote($path + "." + $extmod)) format(quote($format)), comma);
    @if $ext == eot{ 
      $src-eot: url(quote($path + "." + $ext));
    }
  }

  @font-face {
    font-family: quote($name);
    src: $src-eot;
    src: $src-all;
    font-style: $style;
    font-weight: $weight;
    font-display: swap;
  }
}

@mixin linear-gradient($gradient) {  
  background: nth(nth($gradient, 2), 1); /* fallback for old browsers */
  background: -webkit-linear-gradient($gradient);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient($gradient); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

// Rating Stars

@mixin rating-stars {
  &:after,
  &:before {
    aspect-ratio: 6;
    background-image: var(--icon-star);
    background-size: contain;
    background-repeat: space;
    bottom: 0;
    content: "";
    height: 13px;
    left: 0;
    position: absolute;
  }

  &:before {
    filter: var(--rating-star-empty-filter);
  }

  &:after {
    filter: var(--rating-star-color-filter);
        
    @at-root  {

      .node-rating-0-5#{&} {
        clip-path: polygon(0 0, 8.2% 0%, 8.2% 100%, 0% 100%);
      }

      .node-rating-1-0#{&} {
        clip-path: polygon(0 0, 20% 0%, 20% 100%, 0% 100%);
      }

      .node-rating-1-5#{&} {
        clip-path: polygon(0 0, 29% 0%, 29% 100%, 0% 100%);
      }

      .node-rating-2-0#{&} {
        clip-path: polygon(0 0, 40% 0%, 40% 100%, 0% 100%);
      }

      .node-rating-2-5#{&} {
        clip-path: polygon(0 0, 49.5% 0%, 49.5% 100%, 0% 100%);
      }

      .node-rating-3-0#{&} {
        clip-path: polygon(0 0, 60% 0%, 60% 100%, 0% 100%);
      }

      .node-rating-3-5#{&} {
        clip-path: polygon(0 0, 70.2% 0%, 70.2% 100%, 0% 100%);
      }

      .node-rating-4-0#{&} {
        clip-path: polygon(0 0, 80% 0%, 80% 100%, 0% 100%);
      }

      .node-rating-4-5#{&} {
        clip-path: polygon(0 0, 91% 0%, 91% 100%, 0% 100%);
      }

      .node-rating-5-0#{&} {
      }
    }
  }
}

// Links

@mixin link-underline {
  color: var(--link-text-color);
  text-decoration-color: var(--link-underline-color);
  text-decoration-line: var(--link-underline-line);
  text-decoration-thickness: var(--link-underline-thickness);
  text-underline-offset: var(--link-underline-offset);

  &:is(:hover,:active){
    color: var(--link-text-color--hover);
    text-decoration-color: var(--link-underline-color--hover);
  }
}

@mixin link-button {
  @include font-family($cta-font-family);
  background-color: var(--cta-background-color);
  border: var(--cta-border);
  border-radius: var(--cta-border-radius);
  color: var(--cta-text-color);
  cursor: pointer;
  display: inline-block;
  font-size: var(--cta-font-size);
  line-height: var(--cta-line-height);
  height: fit-content;
  margin: 0;
  padding: var(--cta-padding-top) var(--cta-padding-sides) var(--cta-padding-bottom);
  text-align: center;
  text-decoration: none;

  &:is(:hover,:active) {
    background-color: var(--cta-background-color--hover);
    color: var(--cta-text-color--hover);
  }

  &:is-last-child {
    margin-bottom: 0;
  }
}

// Icons

@mixin icon-base {
  aspect-ratio: 1;
  background-position: center;
  background-repeat: no-repeat;  
  display: inline-block;
  content: "";
  font-size: 0;
  height: 20px;
  line-height: 1;
  vertical-align: middle;
}

@mixin icon($icon) {
  @include icon-base;
  background-image: url('#{$assets-path}/images/icons/icon-#{$icon}.svg');

  [data-color-scheme=dark] & {
    filter: invert(1);
  }
}

@mixin icon-outline($icon) {
  @include icon-base;
  background-color: var(--icon-#{$icon}-background-color, $body-container-background-color);
  background-image: url('#{$assets-path}/images/icons/icon-#{$icon}.svg');
  background-size: 60%;
  border-radius: var(--icon-border-radius);
  filter: var(--icon-#{$icon}-outline-filter, unset);
  height: 22px;

  [data-color-scheme=dark] & {
    filter: var(--icon-#{$icon}-outline-filter, invert(1));
  }
}

@mixin icon-block($icon) {
  padding-left: calc(var(--body-container-padding-sides) + 40px);

  > :first-child {
    position: relative;

    &:before {
      @include icon-base;
      background-image: url('#{$assets-path}/images/icons/icon-#{$icon}.svg');
      display: block;
      left: -40px;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);

      [data-color-scheme=dark] & {
        filter: invert(1);
      }
    }
  }
}

@mixin icon-button($icon) {
  @include icon-base;
  background-image: url('#{$assets-path}/images/icons/icon-#{$icon}.svg');
  filter: var(--icon-button-filter);

  [data-color-scheme=dark] & {
    filter: var(--icon-button-filter);
  }
}

// sticky header adjustment

@mixin sticky-header-offset($offset) {
  position: sticky;
  position: -webkit-sticky;
  top: #{$offset};
  transition: top 0.2s ease-in-out;

  .header-top--scrolled-visible &{
    @include tablet-max {
      top: calc(#{$offset} + var(--header-top-height--scrolled));
    }

    @include tablet {
      top: calc(#{$offset} + var(--header-top-height--tablet-scrolled));
    }
  }

  .shop-bar--visible & {
    @include tablet-max {
      top: $offset;
    }
    
    @include tablet {
      top: calc(#{$offset} + 75px);
    }
  }
}

// Text Input

@mixin text-input {
  background: var(--body-container-background-color);
  border: 1px solid var(--body-container-border-color);
  border-radius: var(--cta-border-radius);
  padding: 9px 15px;

  &::placeholder {
    color: inherit;
    opacity: 0.66;
    @include font-family($text-input-font-family);
  }
}