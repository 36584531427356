.block {
  & .contextual-links-wrapper {
    right:32px;
  }
  
  .swipe &{
    margin: 0 0;
    position: relative;
  }
}

@include mobile-to-tablet {
  .block--hidden\@tablet {
    display: none;
  }
}


.block__header {
  @include font-family($block-header-font-family);
  // margin: 0;
  position: relative;
  font-weight: $block-header-font-weight;
  font-size: 20px;
  font-size: clamp(18px, 4vw, 24px);
  color: var(--block-header-text-color);
  width: 100%;
  border-bottom: var(--block-header-border-bottom);
  margin: 0 0 10px 0;
  padding: $block-header-padding;

  a:focus,
  a:hover {
    color: var(--block-header-text-color--hover);
  }

  a, span {
    text-decoration: none;
    margin-right: 15px;
    display: inline-block;
    color: inherit;
  }

  .page-new-homepage &,
  .block--deals &,
  .block--best &{
    @include font-family($font-heading-bold);
    padding-bottom: 5px;
    margin-bottom: 10px;
  }

  .block-cover & {
    @include tablet {
      display: none;
    }
  }
}

.block__swiper-next-trigger{
  @extend %button-reset;
  @include font-family($font-heading-semibold);
  position: absolute;
  top: 1px;
  right: 0;
  color: #696969;
  font-size: 17px;
}

.block__header--mini-centered {
  font-size: 28px;
  margin: 0 0 1em;
  text-align: center;
}

.block__alternate-homepage{
  display: none;
}

.alternate-homepage .block__alternate-homepage{
  @extend %button-reset;
  @include font-family($font-paragraph-light);
  padding: 3px 10px;
  color: #939393;
  border-radius: 3px;
  font-size: 16px;
  display: block;

  @include tablet{
    display: inline-block;
  }

  .far{
    width: 15px;
    text-align: left;
  }

}

.alternate-homepage--active .block__alternate-homepage {
  background: $color-brand-primary;
  color: #fff;

  .far::before{
    content: "\f14a";
  }

}

.block__subheader {
  @include font-family($font-paragraph-light);
  color: #FA5738;
  padding: 0;
  margin: 0;
  font-size: 13px;

  @include tablet{
    position: absolute;
    margin-top: -21px;
  }
}

.block > .row > .block__column {
  position: relative;
}

.block--big-bottom-ad > .row .row:nth-child(1) {
  @include desktop {
    margin-bottom: 140px;
  }
}

.block--feature-wide {
  margin-top: 20px;
}

.block--list-single-col {
  max-width: var(--section-wide-desktop-max-width);
  margin:auto;
  margin-top: 20px;
}


.block__embed{
  margin-bottom: 20px;

  @include tablet{
    margin-bottom: 0;
  }
}

.block--deals .block__column .row > div:nth-child(n+4),
.block--best .block__column .row > div:nth-child(n+4) {
  display: none;

  @include tablet{
    display: block;
  }
}

.block--deals .block__column .row > div:nth-child(n+9){
  @include tablet{
    display: none;
  }

  @include desktop{
    display: block;
  }
}

.block--deals .row--ajax,
.block--best .row--ajax{
  display: none;

  @include tablet{
    display: flex;
  }
}

.block--ad.block--leaderboard{
  margin-bottom: 15px;
}

@import './block-topic/block-topic';
@import './block-destination/product';
@import './block-cover/block-cover';
@import './block-story/block-story-reviews.scss';
@import '../blocks/sidebar-promo/block-sidebar-promo';