$product-spacing: 35px !default;
$product-spacing--tablet: 50px !default;
$product-font-size: 14px !default;

$product-thumb-width: 78px !default;
$product-thumb-width--desktop: 92px !default;
$product-thumb-height: 81px !default;
$product-thumb-border-radius: 11px !default;
$product-thumb-margin-right: 12px !default;
$product-thumb-margin-right--tablet: 15px !default;

$product-title-color: $color-brand-secondary !default;
$product-title-font-size: 1.2em !default;
$product-title-font-family: $font-heading-bold;
$product-title-margin: 0 0 .1em !default;

$product-content-font-size: .93em !default;
$product-content-font-weight: 300 !default;

$product-desc-color: #000 !default;
$product-desc-margin: 0 !default;
$product-desc-font-family: $font-paragraph-light;

.product {
  margin-bottom: $product-spacing;
  font-size: $product-font-size;

  @include tablet {
    margin-bottom: $product-spacing--tablet;
  }
}

.product__thumb {
  border-radius: $product-thumb-border-radius;
  float: left;
  height: $product-thumb-height;
  margin-right: $product-thumb-margin-right;
  width: $product-thumb-width;

  @include tablet {
    margin-right: $product-thumb-margin-right--tablet;
  }

  @include desktop {
    width: $product-thumb-width--desktop;
  }
}

.product__title {
  @include font-family($product-title-font-family);
  font-size: $product-title-font-size;
  font-weight: normal;
  margin: $product-title-margin;
}

.product__link {
  color: $product-title-color;
  display: block;

  &:is(:hover,:focus) {
    text-decoration: none;
  }
}

.product__content {
  font-size: $product-content-font-size;
  overflow: hidden;
  font-weight: $product-content-font-weight;
  padding: 0 28px 0 0;
}

.product__desc {
  @include font-family($product-desc-font-family);
  color: $product-desc-color;
  margin: $product-desc-margin;
  word-break: break-word;
}
